body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--platform-black);
  color: var(--platform-white);
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

:root {
  --platform-black: #060E15;
  --platform-white: #F6F7EB;
  --platform-dark-blue: #2B95B7;
  --platform-light-blue: #4EBFEE;
  --shadow-color: rgba(0, 0, 0, 0.25);
  --std-btn-hover: rgba(211, 220, 223, 0.8);
  --light-dark: #717171;
  --input-bg: rgba(120, 120, 120, 0.6);
  --error: #F98989;
}
