.auth-page-outer-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
}

.left-blue-bg_ctn {
    position: absolute;
    left: 50%;
    right: 0;
    top: 8vh;
    bottom: 8vh;
    background-color: var(--platform-light-blue);
    z-index: 0;
}

.auth-page_ctn {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 1240px;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 1;
}

.auth-page_ctn .action-ctn {
    flex: 2;
    height: 84vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.auth-page_ctn .action-ctn img {
    max-width: 200px;
}

.form-welcome {
    margin-bottom: 2rem;
}

.auth-page_ctn .action-ctn .form-welcome h2 {
    font-size: 35px;
}

.auth-page_ctn .action-ctn .checkbox-ctn {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.auth-page_ctn .action-ctn .form-alternative-actions_ctn {
    margin-top: 1rem;
    font-size: 11px;
}

.auth-page_ctn .action-ctn .form-alternative-actions_ctn p {
    margin-bottom: 0.5rem;
}

.auth-page_ctn .action-ctn a {
    text-decoration: underline;
    cursor: pointer;
}

.auth-page_ctn .action-ctn .form-footer_ctn {
    display: flex;
    flex-direction: row;
    font-size: 11px;
}

.auth-page_ctn .action-ctn .form-footer_ctn a,
.auth-page_ctn .action-ctn .form-footer_ctn p{
    margin-right: 0.5rem;
}

.auth-page_ctn .info-ctn {
    flex: 3;
    box-sizing: border-box;
    margin-left: 4rem;
    background-color: var(--platform-light-blue);
    height: 84vh;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 4rem;
}

.auth-page_ctn .info-ctn h1 {
    font-size: 64px;
    line-height: 60px;
}

.auth-page_ctn .info-ctn p {
    font-size: 24px;
    margin-top: 1rem;
}
