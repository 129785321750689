.checkbox-ctn {
    display: flex;
    align-items: center;
    padding: .5rem 0;
}

.checkbox-ctn .checkbox-outer {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: 2px;
    background-color: var(--input-bg);
    margin-right: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.checkbox-ctn .checkbox-inner {
    width: 12px;
    height: 12px;
    border-radius: 1px;
    background-color: white;
    transition: all 0.2s ease-out;
}

.checkbox-ctn p {
    font-size: 12px;
    font-weight: 400;
    line-height: 11px;
    cursor: pointer;
}
