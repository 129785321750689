.ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.innerCtn {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    max-width: 1240px;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
}

.innerCtn > img {
    position: absolute;
    left: 20px;
    top: 4vh;
    max-width: 200px;
}

.fromCtn {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

