.text-input_ctn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    position: relative;
}

.text-input_ctn input,
.text-input_ctn label {
    display:block;
}

.text-input_ctn label {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    width: 100%;
}

.text-input_ctn input {
    margin-top: 6px;
    font-size: 1rem;
    padding: 0.8rem;
    border: 1px solid white;
    background: var(--input-bg);
    box-shadow: 0 4px 4px var(--shadow-color);
    backdrop-filter: blur(7px);
    border-radius: 5px;
    resize: none;
    box-sizing: border-box;
    width: 100%;
    color: white;
    transition: all .2s ease-out;
}

.text-input_ctn input:hover {
    border: 1px solid var(--platform-light-blue);
}

.text-input_ctn input:focus {
    outline: none;
    border: 1px solid var(--platform-light-blue);
}

.text-input_ctn input::placeholder {
    color: rgba(255,255,255,0.3);
}

.text-input_ctn .below-msg_ctn {
    position: absolute;
    left: 0;
    right: 0;
    top: 73px;
}

.text-input_ctn .info-msg {
    margin-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
}

.text-input_ctn .error-msg {
    margin-top: 4px;
    font-size: 13px;
    color: var(--error);
}
