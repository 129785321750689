.alert_ctn {
    position: absolute;
    top: 10vh;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    z-index: 10;
    justify-content: center;
    justify-items: center;
}

.alert_ctn > div {
    width: 100%;
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0 20px;
    border-radius: 10px;
    padding: 1rem 2rem;
}

.alert_ctn .error-bg {
    background-color: var(--error);
}

.alert_ctn .info-bg {
    background-color: #ffffff90;
}
