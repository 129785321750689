.btn_ctn {
    box-shadow: 0 4px 4px var(--shadow-color);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-out;
    cursor: pointer;
}

.btn_ctn:hover {
    opacity: 0.9;
}

.btn_ctn button {
    background-color: transparent;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    padding: 0.9rem 2rem;
    color: white;
    text-shadow: 0 4px 4px var(--shadow-color);
    cursor: pointer;
    transition: all .2s ease-out;
}

.btn_ctn button:hover {
    background-color: transparent;
}

.std-btn {
    background: var(--platform-dark-blue);
    border: 1px solid white;
}

.std-btn:hover {
    background: var(--std-btn-hover);
    border: 1px solid white;
}

.std-btn:hover button {
    color: var(--light-dark);
}

.minor-btn {
    background: var(--platform-dark-blue);
    border: 1px solid white;
}
